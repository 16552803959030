import { get as getNotification, markAsSeen } from 'hcms-transforms/notifications';

import BookingDetails from 'views/Booking/BookingDetails.vue';
import BookingList from 'views/Booking/index.vue';
import Contacts from 'views/Misc/Contacts.vue';
import EmptyRouterView from 'layouts/Empty.vue';
import EngineRun from '@/views/EngineRun.vue';
import Misc from 'views/Misc/index.vue';
import Notifications from 'views/Notifications.vue';
import Overview from 'views/Overview/index.vue';
import Preferences from 'views/Misc/Preferences.vue';
import Router from 'vue-router';
import Vue from 'vue';
import { get as getChangeRequest } from 'hcms-transforms/booking/change_request';
import { getRouteObject } from 'utils/notificationUtils';
import { notify } from 'utils/notify';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/overview',
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/bookings',
    name: 'Booking List',
    component: BookingList,
  },
  {
    path: '/booking/:bookingId',
    component: EmptyRouterView,
    children: [
      {
        path: 'details',
        name: 'Booking Details',
        props: (route) => ({
          bookingId: route.params.bookingId,
        }),
        component: BookingDetails,
      },
    ],
  },
  {
    path: '/booking-change/:changeRequestId',
    name: 'Booking Change',
    async beforeEnter(to, from, next) {
      const changeRequest = await getChangeRequest(to.params.changeRequestId);
      next({
        name: 'Booking Details',
        params: {
          bookingId: changeRequest.newBookingId,
        },
      });
    },
  },
  {
    path: '/more',
    name: 'Misc',
    component: Misc,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/preferences',
    name: 'Preferences',
    component: Preferences,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/past-notifications',
    name: 'Past Notifications',
    component: Notifications,
    props: {
      showPastNotifications: true,
    },
  },
  {
    path: '/booking-notification/:engineRunId',
    name: 'Bookings Notification',
    component: EngineRun,
    props: (route) => ({
      engineRunId: Number(route.params.engineRunId),
    }),
  },
  {
    path: '/acknowledge-notification/:notificationId',
    name: 'Acknowledge Notification',
    async beforeEnter(to, from, next) {
      if (!to.params.notificationId) {
        next({
          name: 'Notifications',
        });
        return;
      }
      try {
        const notification = await getNotification(to.params.notificationId);
        const route = await getRouteObject(notification);
        const baseRoute = { name: 'Notifications' };
        if (!notification.seenDate) {
          await markAsSeen(notification.id);
          notify('Notification has been acknowledged.', 'success');
        }
        if (typeof route === 'object') {
          next(route);
        } else if (typeof route === 'string') {
          window.open(route, '_blank');
          next(baseRoute);
        } else {
          next(baseRoute);
        }
      } catch (error) {
        notify(error, 'error');
        next({
          name: 'Notifications',
        });
      }
    },
  },
];

export default new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
});
