<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>settings</v-icon>
      <v-toolbar-title>More</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>

    <div v-loading="loading.profile">
      <v-flex class="my-3" layout justify-center>
        <v-avatar :size="120">
          <v-img :src="profileImageUrl" />
        </v-avatar>
      </v-flex>
      <div class="text-xs-center">
        <h5 class="headline font-weight-regular">
          {{ titleUtils.toLabel(user.profile.title) }}
          {{ user.profile.firstName }} {{ user.profile.lastName }}
        </h5>
        <p class="body-2 error--text" v-if="suspension" v-loading="loading.suspension">
          (Service suspended
          <template v-if="!suspension.isCurrent">from {{ formatDate(suspension.fromDate) }}</template>
          until {{ formatDate(suspension.toDate) }})
        </p>
      </div>
    </div>

    <v-list>
      <v-list-tile :to="{ name: 'Contacts' }">
        <v-list-tile-avatar>
          <v-icon>person_add</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Contacts </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :to="{ name: 'Preferences' }">
        <v-list-tile-avatar>
          <v-icon>playlist_add_check</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Booking Preferences </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :href="LOG_REQUEST_PATH">
        <v-list-tile-avatar>
          <v-icon>send</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Log Request </v-list-tile-content>
      </v-list-tile>

      <v-list-tile v-if="providerPhone" :href="`tel:${providerPhone}`">
        <v-list-tile-avatar>
          <v-icon>phone</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Call Provider </v-list-tile-content>
      </v-list-tile>

      <v-list-tile v-if="providerEmail" :href="`mailto:${providerEmail}`">
        <v-list-tile-avatar>
          <v-icon>mail</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Email Provider </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import moment from 'moment';
import {
  VAvatar,
  VImg,
  VList,
  VListTile,
  VListTileAvatar,
  VListTileContent,
  VToolbar,
  VToolbarTitle,
} from 'vuetify/lib';

import { titleUtils } from 'hcms-mc-utils';
import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getConfig } from 'hcms-transforms/config';
import { get as getSu } from 'hcms-transforms/su';
import { getAll as getSuSuspensions } from 'hcms-transforms/su/suspend';

import { LOG_REQUEST as LOG_REQUEST_PATH } from 'hcms-constants/APP_PATHS';

import NotificationBell from 'components/NotificationBell.vue';

const PROVIDER_CONFIG = 'PROVIDER_DETAILS';

export default {
  name: 'Misc',

  components: {
    NotificationBell,
    VAvatar,
    VImg,
    VList,
    VListTile,
    VListTileAvatar,
    VListTileContent,
    VToolbar,
    VToolbarTitle,
  },

  constants: {
    LOG_REQUEST_PATH,
    titleUtils,
  },

  data() {
    return {
      loading: {
        suspension: false,
        profile: false,
      },
      user: {
        profile: {},
      },
      profileImageUrl: 'img/no-photo-man.png',
      suspension: '',
      providerPhone: '',
      providerEmail: '',
    };
  },
  computed: {
    NOW() {
      return moment();
    },
  },

  methods: {
    async getProfile() {
      this.loading.profile = true;
      try {
        this.user = await getSu(this.userName);
        if (this.user.profile.profileImageId.length) {
          const attachment = await getAttachment(this.user.profile.profileImageId[0]);
          this.profileImageUrl = attachment.url;
        }
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.profile = false;
    },
    async getSuspension() {
      this.loading.suspension = true;
      try {
        const suspensions = await getSuSuspensions(this.userName, {
          end_date_gte: this.NOW.toISOString(),
        });
        if (suspensions.length > 0) {
          this.suspension = suspensions[0];
          this.suspension.isCurrent = this.suspension.fromDate.isBefore(this.NOW);
        }
      } catch (error) {
        this.$notify(error, 'error');
      }
      this.loading.suspension = false;
    },
    async getProviderDetails() {
      try {
        const config = await getConfig([PROVIDER_CONFIG]);
        this.providerPhone = config[PROVIDER_CONFIG].getValue('provider_phone');
        this.providerEmail = config[PROVIDER_CONFIG].getValue('provider_email');
      } catch (error) {
        this.$notify(error, 'error');
      }
    },
  },

  async created() {
    this.getProfile();
    this.getSuspension();
    this.getProviderDetails();
  },
};
</script>
